import { api } from "./api";

export const costCentersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCostCenters: builder.query({
      query: () => "api/Acc/CostCenters",
      providesTags: ["costCenters"],
    }),
    addCostCenter: builder.mutation({
      query: (formData) => ({
        url: "api/Acc/CostCenters",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["costCenters"],
    }),
    editCostCenter: builder.mutation({
      query: (formData) => ({
        url: "api/Acc/CostCenters",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["costCenters"],
    }),
    deleteCostCenter: builder.mutation({
      query: (costCenterId) => ({
        url: `api/Acc/CostCenters?CostCenterID=${costCenterId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["costCenters"],
    }),
  }),
});

export const {
  useGetCostCentersQuery,
  useAddCostCenterMutation,
  useEditCostCenterMutation,
  useDeleteCostCenterMutation,
} = costCentersApi;
