import { Form, Input, Modal, Radio, Select, Space } from "antd";
import {
  ACCOUNT_GROUPS,
  ACCOUNT_NATURES,
  ACCOUNT_TYPES,
} from "../../../constants/accountsConstants";

interface Props {
  form: any;
  accounts: any;
  isEditing: boolean;
  isModalOpen: boolean;
  handleOk: (values: any) => void;
  handleCancel: () => void;
  initialValues?: any;
  loading: boolean;
}

const FormModal = ({
  form,
  accounts,
  isEditing,
  isModalOpen,
  handleOk,
  handleCancel,
  initialValues,
  loading,
}: Props) => {
  return (
    <Modal
      width={1000}
      className="max-h-[80vh] overflow-auto"
      title={isEditing ? "تعديل حساب" : "إضافة حساب"}
      open={isModalOpen}
      onCancel={handleCancel}
      cancelText="خروج"
      okText={isEditing ? "تعديل" : "إضافة"}
      onOk={() => form.submit()}
      centered
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOk}
        initialValues={initialValues} // تحميل القيم عند التعديل
        className="grid grid-cols-1 md:grid-cols-2 gap-3"
      >
        {/* كود الحساب */}

        {isEditing && (
          <Form.Item name="Id" label="كود الحساب" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        )}

        <Form.Item name="AccID" label="رقم الحساب" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        {/* اسم الحساب بالعربي */}
        <Form.Item
          name="AccName"
          label="اسم الحساب بالعربية"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        {/* اسم الحساب بالإنجليزية */}
        <Form.Item name="AccEngName" label="اسم الحساب بالإنجليزية">
          <Input />
        </Form.Item>

        {/* الحساب الأعلى */}
        <Form.Item name="UpAccId" label="الحساب الأعلى">
          <Select showSearch placeholder="اختر الحساب الأعلى">
            {accounts.map((acc: any) => (
              <Select.Option key={acc.AccID} value={String(acc.AccID)}>
                {acc.AccName}
              </Select.Option>
            ))}
            <Select.Option value="1001">1001</Select.Option>
            <Select.Option value="1002">1002</Select.Option>
          </Select>
        </Form.Item>

        {/* التوجيه المحاسبي */}
        <Form.Item name="AccDir" label="التوجيه المحاسبي">
          <Select showSearch placeholder="اختر التوجيه">
            <Select.Option value={0}>غير موجه</Select.Option>
            <Select.Option value={1}>موجه</Select.Option>
          </Select>
        </Form.Item>

        {/* التصنيفات */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
          {/* طبيعة الحساب */}
          <Form.Item name="AccNeture" label="طبيعة الحساب">
            <Radio.Group>
              <Space direction="vertical">
                {ACCOUNT_NATURES.map((item) => (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>

          {/* نوع الحساب */}
          <Form.Item name="AccType" label="نوع الحساب">
            <Radio.Group>
              <Space direction="vertical">
                {ACCOUNT_TYPES.map((item) => (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>

          {/* مجموعة الحساب */}
          <Form.Item name="AccGroup" label="مجموعة الحساب">
            <Radio.Group>
              <Space direction="vertical">
                {ACCOUNT_GROUPS.map((item) => (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default FormModal;
