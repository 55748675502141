import Heading from "components/Heading/Heading";
import { Button, Form, Modal, message } from "antd";
import { useState } from "react";
import DataTable from "../../../components/Tables/DataTable";
import FormModal from "./FormModal";
import {
  useAddRestrictionMutation,
  useDeleteRestrictionMutation,
  useGetAccountsQuery,
  useGetAllRestrictionsQuery,
  useUpdateRestrictionMutation,
} from "../../../redux/Service/Accounts";
import dayjs from "dayjs";
import { useGetBranchesQuery } from "../../../redux/Service/branches";
import { useGetCostCentersQuery } from "../../../redux/Service/costCenter";
import { useGetAccDailysQuery } from "../../../redux/Service/AccDailysApi";

function IndexOpeningRestrictions() {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [subData, setSubData] = useState<any>([]);

  const {
    data: restrictionsData,
    isLoading,
    isError,
  } = useGetAllRestrictionsQuery(undefined);

  const restrictions = restrictionsData?.ResponseObject ?? [];

  const {
    data: accountsData,
    isLoading: isAccLoading,
    isError: isAccError,
  } = useGetAccountsQuery(undefined);

  const accounts = accountsData?.ResponseObject ?? [];

  const {
    data: branchesData,
    isLoading: isBranchesLoading,
    isError: isBranchesError,
  } = useGetBranchesQuery(undefined);

  const branches = branchesData?.ResponseObject ?? [];

  const {
    data: costCentersData,
    isLoading: iscostCentersLoading,
    isError: iscostCentersError,
  } = useGetCostCentersQuery(undefined);

  const costCenters = costCentersData?.ResponseObject ?? [];

  const {
    data: accDailysData,
    isLoading: isAccDailysLoading,
    isError: isAccDailysError,
  } = useGetAccDailysQuery(undefined);

  const accDailys = accDailysData?.ResponseObject ?? [];

  const [deleteRestriction, { isLoading: isDeleting }] =
    useDeleteRestrictionMutation();
  const [addRestriction, { isLoading: isAdding }] = useAddRestrictionMutation();
  const [updateRestriction, { isLoading: isUpdating }] =
    useUpdateRestrictionMutation();
  const handleDeletClick = (record: any) => {
    console.log(record);

    Modal.confirm({
      title: "تأكيد الحذف",
      content: `هل أنت متأكد أنك تريد حذف القيد "${record.DocID}"؟`,
      okText: "حذف",
      okType: "danger",
      cancelText: "إلغاء",
      onOk: async () => {
        try {
          const response = await deleteRestriction(record.DocID).unwrap();

          if (response?.ResponseObject == true) {
            message.success("تم حذف القيد بنجاح");
          } else {
            message.error("حدث خطأ أثناء الحذف");
          }
        } catch (error) {
          message.error("فشل الاتصال بالخادم");
        }
      },
    });
  };

  const columns: any = [
    {
      title: "كود القيد",
      dataIndex: "DocID",
      key: "DocID",
    },
    {
      title: "تاريخ القيد",
      dataIndex: "DocDate",
      key: "DocDate",
      width: 180,
    },
    {
      title: "اسم القيد ",
      dataIndex: "AccDailyName",
      key: "AccDailyName",
    },

    {
      title: "نوع القيد",
      dataIndex: "FirstBal",
      key: "FirstBal",

      render: (_: any, record: any) => {
        return record.FirstBal == 0 ? "يومي" : "افتتاحي";
      },
    },
    {
      title: "ملاحظات",
      dataIndex: "Notes",
      key: "Notes",
    },
  ];

  const handleOk = async (values) => {
    const body = { ...values, AccItems: subData };
    // return;

    try {
      let response;

      if (isEditing) {
        response = await updateRestriction(body).unwrap(); // طلب التحديث
      } else {
        response = await addRestriction(body).unwrap(); // طلب الإضافة
      }

      if (response?.ResponseObject == true) {
        message.success(
          isEditing ? "تم تعديل الحساب بنجاح" : "تمت إضافة الحساب بنجاح"
        );
        setIsModalOpen(false);
        resetModal();
      } else {
        message.error("حدث خطأ أثناء العملية");
      }
    } catch (error) {
      message.error("فشل الاتصال بالخادم");
    }
  };

  function resetModal() {
    form.resetFields();
    setIsEditing(false);
    setSubData([]);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
    resetModal();
  };

  const handleEditClick = (record: any) => {
    // return;
    setIsEditing(true);
    setIsModalOpen(true);

    const recordData = { ...record };

    if (recordData.DocDate) {
      recordData.DocDate = dayjs(recordData.DocDate);
    }

    setTimeout(() => {
      form.setFieldsValue(recordData);
      setSubData(recordData.AccItems);
    }, 0);
  };

  return (
    <>
      <Heading name={"القيود "} />
      <div className="flex flex-wrap gap-4 relative">
        <div className="grid justify-start">
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            إضافه
          </Button>
        </div>

        <FormModal
          form={form}
          accounts={accounts}
          isEditing={isEditing}
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          loading={isUpdating || isAdding}
          subData={subData}
          setSubData={setSubData}
          branches={branches}
          costCenters={costCenters}
          accDailys={accDailys}
        />

        <DataTable
          columns={columns}
          data={restrictions}
          handleEditClick={handleEditClick}
          handleDeletClick={handleDeletClick}
          isLoading={isLoading || isDeleting}
        />
      </div>
    </>
  );
}

export default IndexOpeningRestrictions;
