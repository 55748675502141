import { api } from "./api";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (userData) => ({
        url: "api/Identity/Users",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["user"],
    }),
    login: builder.mutation({
      query: (loginData) => ({
        url: "api/Identity/LogIn",
        method: "POST",
        body: loginData,
      }),
      invalidatesTags: ["user"],
    }),
    logout: builder.mutation({
      query: () => ({
        url: "api/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["user"],
    }),
    getLogedUser: builder.query({
      query: () => "api/auth/show",
      providesTags: ["user"],
    }),
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useLogoutMutation,
  useGetLogedUserQuery,
} = authApi;
