import React, { useState } from "react";
import {
  Form,
  Popconfirm,
  Table,
  Button,
  Select,
  Space,
  Input,
  InputNumber,
  DatePicker,
  Switch,
  Slider,
  Radio,
  Checkbox,
} from "antd";

const { Option } = Select;

export interface EditableColumnType<T> {
  title: string;
  dataIndex: string;
  width?: number;
  editable?: boolean;
  inputType?:
    | "text"
    | "number"
    | "select"
    | "date"
    | "checkbox"
    | "radio"
    | "switch"
    | "slider"
    | any;
  options?: Array<{ value: string | number; label: string }>;
  render?: (text: any, record: T, index: number) => React.ReactNode;
}

interface AddingTableProps<T> {
  columns: EditableColumnType<T>[];
  data: T[];
  setData: (data: T[]) => void;
  /** Field name in data used as the unique identifier.
      Defaults to "RowNo" (as returned from your API). */
  rowKeyField?: string;
}

const AddingTable = <T extends { [key: string]: any }>({
  columns,
  data,
  setData,
  rowKeyField = "RowNo",
}: AddingTableProps<T>) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  // Use a counter to generate new temporary keys for added rows
  const [count, setCount] = useState(data.length + 1);

  // Helper to retrieve the unique identifier from a record
  const getKey = (record: T) => {
    return record[rowKeyField] ? String(record[rowKeyField]) : "";
  };

  const isEditing = (record: T) => getKey(record) === editingKey;

  const edit = (record: T) => {
    form.setFieldsValue({ ...record });
    setEditingKey(getKey(record));
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (uniqueKey: string) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => getKey(item) === uniqueKey);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const deleteRow = (uniqueKey: string) => {
    const newData = data.filter((item) => getKey(item) !== uniqueKey);
    setData(newData);
  };

  const addNewRow = () => {
    // Create a new row with a temporary unique value in the rowKeyField
    const newRow: any = { [rowKeyField]: data.length + 1 };
    // Initialize other fields as empty strings if needed
    columns.forEach((col) => {
      if (col.dataIndex && newRow[col.dataIndex] === undefined) {
        newRow[col.dataIndex] = "";
      }
    });
    form.resetFields();
    setData([...data, newRow]);
    setCount(count + 1);
    // Optionally, immediately begin editing the new row
    edit(newRow);
  };

  // EditableCell renders the appropriate input based on the inputType
  const EditableCell: React.FC<any> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    options,
    ...restProps
  }) => {
    let inputNode;
    switch (inputType) {
      case "number":
        inputNode = <InputNumber />;
        break;
      case "select":
        inputNode = (
          <Select showSearch placeholder="Select" optionFilterProp="children">
            {options?.map((opt) => (
              <Option key={opt.value} value={opt.value}>
                {opt.label}
              </Option>
            ))}
          </Select>
        );
        break;
      case "date":
        inputNode = <DatePicker />;
        break;
      case "checkbox":
        inputNode = <Checkbox />;
        break;
      case "radio":
        inputNode = <Radio.Group options={options} />;
        break;
      case "switch":
        inputNode = <Switch />;
        break;
      case "slider":
        inputNode = <Slider />;
        break;
      case "text":
      default:
        inputNode = <Input />;
        break;
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  // Merge passed columns with onCell for editable cells
  const mergedColumns = columns.map((col) => {
    if (!col.editable) return col;
    return {
      ...col,
      onCell: (record: T) => ({
        record,
        inputType: col.inputType || "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: col.options || [],
      }),
    };
  });

  // Append an operations column for editing and deletion actions
  const finalColumns = [
    ...mergedColumns,
    {
      title: "الإجراءات",
      dataIndex: "operation",
      render: (_: any, record: T) => {
        const editable = isEditing(record);
        return editable ? (
          <Space size="middle">
            <Button onClick={() => save(getKey(record))}>Save</Button>
            <Popconfirm title="هل أنت متأكد من الإلغاء؟" onConfirm={cancel}>
              <Button>Cancel</Button>
            </Popconfirm>
          </Space>
        ) : (
          <Space size="middle">
            <Button onClick={() => edit(record)} disabled={editingKey !== ""}>
              Edit
            </Button>
            <Popconfirm
              title="هل أنت متأكد من الحذف؟"
              onConfirm={() => deleteRow(getKey(record))}
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="mb-4">
        <Button onClick={addNewRow} type="primary">
          إضافة
        </Button>
      </div>
      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={data}
          columns={finalColumns}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName="editable-row"
          pagination={false}
          rowKey={rowKeyField}
        />
      </Form>
    </>
  );
};

export default AddingTable;
