import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseUrl = "https://api.prosofteg.com:6001/";

const dynamicBaseQuery = async (args, api, extraOptions) => {
  const userData = localStorage.getItem("prosoft-erp");
  const basicToken = JSON.parse(userData)?.Token;

  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      headers.set("Accept", "application/json");

      headers.set("Authorization", `Bearer ${basicToken}`);

      return headers;
    },
  });

  return rawBaseQuery(args, api, extraOptions);
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: dynamicBaseQuery,
  tagTypes: [
    "user",
    "customers",
    "countries",
    "vendors",
    "sellers",
    "branches",
    "accounts",
    "restrictions",
    "costCenters",
    "accDailys",
  ],
  endpoints: (builder) => ({}),
});
