import { createSlice } from "@reduxjs/toolkit";

const storedUser = localStorage.getItem("prosoft-erp");
const initialState = storedUser
  ? { isAuthenticated: true, userData: JSON.parse(storedUser) }
  : { isAuthenticated: false, userData: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reduxLogin: (state, action) => {
      state.isAuthenticated = true;
      state.userData = action.payload;
      localStorage.setItem("prosoft-erp", JSON.stringify(action.payload));
    },
    reduxLogout: (state) => {
      state.isAuthenticated = false;
      state.userData = null;
      localStorage.removeItem("prosoft-erp");
    },
  },
});

export const { reduxLogin, reduxLogout } = authSlice.actions;
export default authSlice.reducer;
