import { api } from "./api";

export const branchesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBranches: builder.query({
      query: () => "api/Sys/Branches",
      providesTags: ["branches"],
    }),
    addBranch: builder.mutation({
      query: (formData) => ({
        url: "api/Sys/Branches",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["branches"],
    }),
    editBranch: builder.mutation({
      query: (formData) => ({
        url: "api/Sys/Branches",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["branches"],
    }),
    deleteBranch: builder.mutation({
      query: (branchId) => ({
        url: `api/Sys/Branches?BranchID=${branchId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["branches"],
    }),
  }),
});

export const {
  useGetBranchesQuery,
  useAddBranchMutation,
  useEditBranchMutation,
  useDeleteBranchMutation,
} = branchesApi;
