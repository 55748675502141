export const ACCOUNT_NATURES = [
  { value: 1, label: "مدين" },
  { value: 2, label: "دائن" },
];

export const ACCOUNT_TYPES = [
  { value: 1, label: "رئيسي" },
  { value: 2, label: "فرعي" },
];

export const ACCOUNT_GROUPS = [
  { value: 1, label: "أصول" },
  { value: 2, label: "خصوم" },
  { value: 3, label: "مصروفات" },
  { value: 4, label: "إيرادات" },
  { value: 5, label: "أخرى" },
];

export const BAL_TYPES = [
  { value: 0, label: "يومي" },
  { value: 1, label: "افتتاحي" },
];
