import { api } from "./api";

export const AccountsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query: () => "api/Acc/Accs",
      providesTags: ["accounts"],
    }),
    addAccount: builder.mutation({
      query: (formData) => ({
        url: "api/Acc/Accs",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["accounts"],
    }),
    editAccount: builder.mutation({
      query: (formData) => ({
        url: "api/Acc/Accs",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["accounts"],
    }),
    deleteAccount: builder.mutation({
      query: (accId) => ({
        url: `api/Acc/Accs?AccId=${accId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["accounts"],
    }),

    // Configuration
    getAccountsSetting: builder.query({
      query: () => "api/Acc/Configuration",
      providesTags: ["accounts"],
    }),
    updateAccountsSetting: builder.mutation({
      query: (formData) => ({
        url: "api/Acc/Configuration",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["accounts"],
    }),

    // Restrictions
    getAllRestrictions: builder.query({
      query: () => "api/Acc/AccTrans",
      providesTags: ["restrictions"],
    }),
    addRestriction: builder.mutation({
      query: (formData) => ({
        url: "api/Acc/AccTrans",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["restrictions"],
    }),
    deleteRestriction: builder.mutation({
      query: (DocId) => ({
        url: `api/Acc/AccTrans?DocId=${DocId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["restrictions"],
    }),
    updateRestriction: builder.mutation({
      query: (formData) => ({
        url: "api/Acc/AccTrans",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["restrictions"],
    }),
  }),
});

export const {
  useGetAccountsQuery,
  useAddAccountMutation,
  useEditAccountMutation,
  useDeleteAccountMutation,
  // Configuration
  useGetAccountsSettingQuery,
  useUpdateAccountsSettingMutation,

  // Restrictions
  useGetAllRestrictionsQuery,
  useAddRestrictionMutation,
  useDeleteRestrictionMutation,
  useUpdateRestrictionMutation,
} = AccountsApi;
