import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
} from "antd";
import AddingTable from "components/Tables/AddingTable";
import TextArea from "antd/es/input/TextArea";
import { BAL_TYPES } from "../../../constants/accountsConstants";

interface Props {
  form: any;
  accounts: any;
  isEditing: boolean;
  isModalOpen: boolean;
  handleOk: (values: any) => void;
  handleCancel: () => void;
  initialValues?: any;
  loading: boolean;
  subData: any[];
  setSubData: any;
  branches: any[];
  costCenters: any[];
  accDailys: any[];
}

const FormModal = ({
  form,
  accounts,
  isEditing,
  isModalOpen,
  handleOk,
  handleCancel,
  initialValues,
  loading,
  subData,
  setSubData,
  branches,
  costCenters,
  accDailys,
}: Props) => {
  const columns = [
    {
      title: "م",
      dataIndex: "RowNo",
      width: 20,
      editable: false,
    },
    {
      title: " الحساب",
      dataIndex: "AccId",
      width: 130,
      editable: true,
      inputType: "select",
      // Pass options dynamically from your accounts list:
      options: accounts.map((account: any) => ({
        value: String(account.AccID),
        label: account.AccName,
      })),
      render: (text: string) => {
        const account = accounts.find((a: any) => String(a.AccID) === text);
        return account ? account.AccName : text;
      },
    },
    {
      title: "مدين",
      dataIndex: "Debit",
      width: 120,
      editable: true,
      inputType: "number",
    },
    {
      title: "دائن",
      dataIndex: "Credit",
      width: 120,
      editable: true,
      inputType: "number",
    },

    {
      title: "مركز التكلفه",
      dataIndex: "CostID",
      width: 130,
      editable: true,
      inputType: "select",
      // Pass options dynamically from your accounts list:
      options: costCenters.map((center: any) => ({
        value: String(center.CostID),
        label: center.CostName,
      })),
      render: (text: string) => {
        const account = costCenters.find((a: any) => String(a.CostID) === text);
        return account ? account.CostName : text;
      },
    },
    {
      title: "ملاحظات",
      dataIndex: "Notes",
      width: 200,
      editable: true,
      inputType: "text",
    },
  ];

  return (
    <Modal
      width={1000}
      className="max-h-[80vh] overflow-auto"
      title={isEditing ? "تعديل قيد" : "إضافة قيد"}
      open={isModalOpen}
      onCancel={handleCancel}
      cancelText="خروج"
      okText={isEditing ? "تعديل" : "إضافة"}
      onOk={() => form.submit()}
      centered
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleOk}
      >
        {/* Data Entry Section */}
        <Space direction="vertical" className="w-full" size="large">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {isEditing && (
              <Form.Item name="DocID" label="رقم القيد">
                <InputNumber placeholder="رقم القيد" className="w-full" />
              </Form.Item>
            )}

            <Form.Item name="BranchId" label="الفرع">
              <Select
                placeholder="الفرع"
                showSearch
                optionFilterProp="children"
                options={branches.map((branch: any) => ({
                  value: branch.BranchID,
                  label: branch.BranchName,
                }))}
                className="w-full"
              />
            </Form.Item>

            <Form.Item name="DocDate" label="تاريخ القيد">
              <DatePicker placeholder="تاريخ القيد" className="w-full" />
            </Form.Item>

            {/* <Form.Item name="entryType" label="نوع القيد">
              <Input placeholder="نوع القيد" className="w-full" />
            </Form.Item> */}
            <Form.Item name="FirstBal" label="نوع القيد">
              <Select
                placeholder="نوع القيد"
                showSearch
                optionFilterProp="children"
                options={BAL_TYPES.map((bal: any) => ({
                  value: bal.value,
                  label: bal.label,
                }))}
                className="w-full"
              />
            </Form.Item>

            <Form.Item name="AccDailyId" label="القيد">
              <Select
                placeholder=""
                showSearch
                optionFilterProp="children"
                options={accDailys.map((bal: any) => ({
                  value: bal.AccDailyId,
                  label: bal.AccDailyName,
                }))}
                className="w-full"
              />
            </Form.Item>
          </div>

          {/* Table Section */}
          <AddingTable
            columns={columns}
            data={subData ?? []}
            setData={setSubData}
          />

          {/* Totals Section */}
          <div className="mt-5">
            <div className="xl:px-10 grid grid-cols-3 gap-14 justify-center">
              <div className="flex justify-between border-b border-primary">
                <p className="font-bold">اجمالي مدين:</p>
                <span className="font-bold">70</span>
              </div>
              <div className="flex justify-between border-b border-primary">
                <p className="font-bold">اجمالي دائن :</p>
                <span className="font-bold">60</span>
              </div>
              <div className="flex justify-between border-b border-primary">
                <p className="font-bold">الفرق :</p>
                <span className="font-bold text-red-500">10</span>
              </div>
            </div>
          </div>

          {/* Feedback Section */}
          <Form.Item name="Notes" label="ملاحظاتك">
            <TextArea rows={4} placeholder="اكتب ملاحظاتك" />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default FormModal;
