import { Table, Space, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useMemo } from "react";

interface DataTableProps<T> {
  data: T[];
  columns: ColumnsType<T>;
  isLoading: boolean;
  handleEditClick: (record: T) => void;
  handleDeletClick: (record: T) => void;
}

const DataTable = <T extends Record<string, any>>({
  data,
  columns,
  isLoading,
  handleEditClick,
  handleDeletClick,
}: DataTableProps<T>) => {
  const actionColumn = useMemo<ColumnsType<T>[number]>(
    () => ({
      title: "الإجراءات",
      key: "action",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="تعديل">
            <EditOutlined
              style={{ color: "#1890ff", cursor: "pointer", fontSize: 18 }}
              onClick={() => handleEditClick(record)}
            />
          </Tooltip>
          <Tooltip title="حذف">
            <DeleteOutlined
              style={{ color: "#ff4d4f", cursor: "pointer", fontSize: 18 }}
              onClick={() => handleDeletClick(record)}
            />
          </Tooltip>
        </Space>
      ),
    }),
    [handleEditClick, handleDeletClick]
  );

  const mergedColumns = useMemo(
    () => [...columns, actionColumn],
    [columns, actionColumn]
  );

  return (
    <Table
      columns={mergedColumns}
      dataSource={data}
      bordered
      scroll={{ y: 350, x: "max-content" }}
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50"],
      }}
      loading={isLoading}
    />
  );
};

export default DataTable;
