import { api } from "./api";

export const accDailysApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccDailys: builder.query({
      query: () => "api/Acc/AccDailys",
      providesTags: ["accDailys"],
    }),
    addAccDaily: builder.mutation({
      query: (formData) => ({
        url: "api/Acc/AccDailys",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["accDailys"],
    }),
    editAccDaily: builder.mutation({
      query: (formData) => ({
        url: "api/Acc/AccDailys",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["accDailys"],
    }),
    deleteAccDaily: builder.mutation({
      query: (accDailyId) => ({
        url: `api/Acc/AccDailys?AccDailyID=${accDailyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["accDailys"],
    }),
  }),
});

export const {
  useGetAccDailysQuery,
  useAddAccDailyMutation,
  useEditAccDailyMutation,
  useDeleteAccDailyMutation,
} = accDailysApi;
