import React, { useState } from "react";
import { Button, Form, Modal, TableProps, message } from "antd";
import "./AccountsTree.css";
import { DataType } from "./Type";
import Heading from "components/Heading/Heading";
import DataTable from "components/Tables/DataTable";
import FormModal from "./FormModal";
import {
  useAddAccountMutation,
  useDeleteAccountMutation,
  useEditAccountMutation,
  useGetAccountsQuery,
} from "../../../redux/Service/Accounts";
import {
  ACCOUNT_GROUPS,
  ACCOUNT_NATURES,
  ACCOUNT_TYPES,
} from "../../../constants/accountsConstants";

function AccountsTree() {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const {
    data: accountsData,
    isLoading,
    isError,
  } = useGetAccountsQuery(undefined);

  const accounts = accountsData?.ResponseObject ?? [];

  const [addAccount, { isLoading: isAdding }] = useAddAccountMutation();
  const [updateAccount, { isLoading: isUpdating }] = useEditAccountMutation();
  const [deleteAccount, { isLoading: isDeleting }] = useDeleteAccountMutation();

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "كود الحساب",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "اسم الحساب",
      dataIndex: "AccName",
      key: "AccName",
      width: 120,
    },
    {
      title: "كود الحساب الاعلى",
      dataIndex: "UpAccId",
      key: "UpAccId",
    },
    {
      title: "طبيعة الحساب",
      key: "AccNeture",
      dataIndex: "AccNeture",
      render: (_, record: any) =>
        ACCOUNT_NATURES.find((nature) => nature.value === record.AccNeture)
          ?.label ?? record.AccNeture,
    },
    {
      title: "نوع الحساب",
      key: "AccType",
      dataIndex: "AccType",
      render: (_, record: any) =>
        ACCOUNT_TYPES.find((type) => type.value === record.AccType)?.label ??
        record.AccType,
    },
    {
      title: "مجموعه الحساب",
      key: "AccGroup",
      dataIndex: "AccGroup",
      render: (_, record: any) =>
        ACCOUNT_GROUPS.find((gp) => gp.value === record.AccGroup)?.label ??
        record.AccGroup,
    },
  ];

  const handleOk = async (values) => {
    try {
      let response;

      if (isEditing) {
        response = await updateAccount(values).unwrap(); // طلب التحديث
      } else {
        response = await addAccount(values).unwrap(); // طلب الإضافة
      }

      if (response?.ResponseObject == true) {
        message.success(
          isEditing ? "تم تعديل الحساب بنجاح" : "تمت إضافة الحساب بنجاح"
        );
        setIsModalOpen(false);
        resetModal();
      } else {
        message.error("حدث خطأ أثناء العملية");
      }
    } catch (error) {
      message.error("فشل الاتصال بالخادم");
    }
  };

  function resetModal() {
    form.resetFields();
    setIsEditing(false);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
    resetModal();
  };

  const handleDeletClick = (record: any) => {
    console.log(record);

    Modal.confirm({
      title: "تأكيد الحذف",
      content: `هل أنت متأكد أنك تريد حذف الحساب "${record.AccName}"؟`,
      okText: "حذف",
      okType: "danger",
      cancelText: "إلغاء",
      onOk: async () => {
        try {
          const response = await deleteAccount(record.AccID).unwrap();

          if (response?.ResponseObject == true) {
            message.success("تم حذف الحساب بنجاح");
          } else {
            message.error("حدث خطأ أثناء الحذف");
          }
        } catch (error) {
          message.error("فشل الاتصال بالخادم");
        }
      },
    });
  };

  const handleEditClick = (record: any) => {
    setIsEditing(true);
    setIsModalOpen(true);

    setTimeout(() => {
      form.setFieldsValue(record);
    }, 0);
  };

  return (
    <>
      <Heading name={"شجره الحسابات"} />
      <div className="flex flex-wrap gap-4 relative">
        <div className="grid justify-start">
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            إضافه
          </Button>
        </div>

        <FormModal
          form={form}
          accounts={accounts}
          isEditing={isEditing}
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          loading={isUpdating || isAdding}
        />

        <DataTable
          columns={columns}
          data={accounts}
          handleEditClick={handleEditClick}
          handleDeletClick={handleDeletClick}
          isLoading={isLoading || isDeleting}
        />
      </div>
    </>
  );
}

export default AccountsTree;
