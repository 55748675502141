import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../components/RootLayout/RootLayout";
import { ErrorPage } from "./ErrorPage";
import Home from "modules/Home/Home";
import CompanyData from "modules/SystemSettings/CompanyData/CompanyData";
import AccountsTree from "modules/Accounts/AccountsTree/AccountsTree";
import Sales from "modules/Sales/Sales/Sales";
import ChangeThePassword from "modules/SystemSettings/ChangeThePassword/ChangeThePassword";
import TabsUserPermissions from "modules/SystemSettings/UserPermissions/TabsUserPermissions";
import BuyOrder from "modules/Purchases/BuyOrder/BuyOrder";
import PurchaseOrder from "modules/Purchases/PurchaseOrder/PurchaseOrder";
import ReturnedPurchases from "modules/Purchases/ReturnedPurchases/ReturnedPurchases";
import PurchaseInvoice from "modules/Purchases/PurchaseInvoice/PurchaseInvoice";
import OfferPrice from "modules/Sales/OfferPrice/OfferPrice";
import OrderedSupply from "modules/Sales/OrderedSupply/OrderedSupply";
import SalesBouncer from "modules/Sales/SalesBouncer/SalesBouncer";
import IndexCustomerCodes from "modules/Sales/Customers/IndexCustomerCodes";
import IndexSupplierCodes from "modules/Purchases/SupplierCodes/IndexSupplierCodes";
import IndexDailyRestrictions from "modules/Accounts/DailyRestrictions/IndexDailyRestrictions";
import IndexOpeningRestrictions from "modules/Accounts/OpeningRestrictions/IndexOpeningRestrictions";
import IndexCompanyNews from "modules/SystemSettings/CompanyNews/IndexCompanyNews";
import Loading from "components/Loading/Loading";
import Settings from "modules/SystemSettings/Settings/IndexSettings";
import AccountsSettings from "modules/Accounts/Settings/AccountsSettings";
import PurchasingSettings from "modules/Purchases/Settings/PurchasingSettings";
import SalesSettings from "modules/Sales/Settings/SalesSettings";
import TableCurrencyRate from "modules/FinancialMovement/CurrencyRate/TableCurrencyRate";
import MoveChecks from "modules/FinancialMovement/MoveChecks/MoveChecks";
import FinancialMovementSettings from "modules/FinancialMovement/Settings/Settings";
import IndexReceiptDocument from "modules/FinancialMovement/ReceiptDocument/IndexReceiptDocument";
import IndexCashingDocument from "modules/FinancialMovement/CashingDocument/IndexCashingDocument";
import IndexDailyRestrictionsGL from "modules/FinancialMovement/DailyRestrictions/IndexDailyRestrictionsGL";
import IndexPredecessor from "modules/FinancialMovement/Predecessor/IndexPredecessor";
import IndexTheCovenant from "modules/FinancialMovement/TheCovenant/IndexTheCovenant";
import StoresSettings from "modules/Stores/Settings/Settings";
import IndexMovementOfItems from "modules/Stores/MovementOfItems/IndexMovementOfItems";
import AdditionStep from "modules/Stores/AdditionStep/AdditionStep";
import ExchangeMovement from "modules/Stores/ExchangeMovement/ExchangeMovement";
import IndexStoreTransferMovement from "modules/Stores/StoreTransferMovement/IndexStoreTransferMovement";
import IndexPhysicalInventoryMovement from "modules/Stores/PhysicalInventoryMovement/IndexPhysicalInventoryMovement";
import FollowUpOnOrders from "modules/FollowUpOnOrders/Settings/FollowUpOnOrders";
import IndexCustomer from "modules/FollowUpOnOrders/Customers/IndexCustomer";
import IndexInvoiceDeliveries from "modules/FollowUpOnOrders/InvoiceDeliveries/IndexInvoiceDeliveries";
import ProductionSettings from "modules/Production/Settings/ProductionSettings";
import IndexWorkOrder from "modules/Production/WorkOrder/IndexWorkOrder";
import IndexReceivingTheWorkOrder from "modules/Production/ReceivingTheWorkOrder/IndexReceivingTheWorkOrder";
import IndexExamination from "modules/Production/Examination/IndexExamination";
import IndexReceiveTheFinishedProduct from "modules/Production/ReceiveTheFinishedProduct/IndexReceiveTheFinishedProduct";
import IndexCustomerInstallmentManagement from "modules/InstallmentManagement/Customers/IndexCustomerInstallmentManagement";
import IndexAddData from "modules/InstallmentManagement/AddData/IndexAddData";
import IndexInstallments from "modules/InstallmentManagement/Installments/IndexInstallments";
import IndexProjects from "modules/ProjectsManagement/Projects/IndexProjects";
import IndexProjectsManagement from "modules/ProjectsManagement/ProjectsManagement/IndexProjectsManagement";
import IndexMission from "modules/ProjectsManagement/Mission/IndexMission";
import ProjectsManagementSettings from "modules/ProjectsManagement/Settings/ProjectsManagementSettings";
import IndexContracting from "modules/Contracting/Contracting/IndexContracting";
import AbstractCodes from "modules/Contracting/AbstractCodes/AbstractCodes";
import IndexOperationTerms from "modules/Contracting/OperationTerms/IndexOperationTerms";
import IndexContractorExtract from "modules/Contracting/ContractorExtract/IndexContractorExtract";
import ContractingSettings from "modules/Contracting/Settings/ContractingSettings";
import IndexCustomerCodesMaintenance from "modules/Maintenance/Customers/IndexCustomerCodesMaintenance";
import MaintenanceOrder from "modules/Maintenance/MaintenanceOrder/MaintenanceOrder";
import MaintenanceInvoice from "modules/Maintenance/MaintenanceInvoice/MaintenanceInvoice";
import MaintenanceApostate from "modules/Maintenance/MaintenanceApostate/MaintenanceApostate";
import InitialRequest from "modules/CRM/InitialRequest/InitialRequest";
import SellOrder from "modules/CRM/SellOrder/SellOrder";
import IndexCustomerCRM from "modules/CRM/CustomerCRM/IndexCustomerCRM";
import IndexCustomerFollowUp from "modules/CRM/CustomerFollowUp/IndexCustomerFollowUp";
import Login from "pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <RootLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },

      // Loading Pages
      { path: "Loading", element: <Loading /> },

      // System Settings
      { path: "Settings/CompanyData", element: <CompanyData /> },
      { path: "Settings/UserPermissions", element: <TabsUserPermissions /> },
      { path: "Settings/ChangeThePassword", element: <ChangeThePassword /> },
      { path: "Settings/Settings", element: <Settings /> },
      { path: "Settings/CompanyNews", element: <IndexCompanyNews /> },

      // Accounts
      { path: "Accounts/AccountsTree", element: <AccountsTree /> },
      {
        path: "Accounts/OpeningRestrictions",
        element: <IndexOpeningRestrictions />,
      },
      {
        path: "Accounts/DailyRestrictions",
        element: <IndexDailyRestrictions />,
      },
      { path: "Accounts/AccountsSettings", element: <AccountsSettings /> },

      // Financial Movement
      {
        path: "FinancialMovement/CurrencyRate",
        element: <TableCurrencyRate />,
      },
      {
        path: "FinancialMovement/ReceiptDocument",
        element: <IndexReceiptDocument />,
      },
      {
        path: "FinancialMovement/CashingDocument",
        element: <IndexCashingDocument />,
      },
      { path: "FinancialMovement/MoveChecks", element: <MoveChecks /> },
      { path: "FinancialMovement/Predecessor", element: <IndexPredecessor /> },
      { path: "FinancialMovement/TheCovenant", element: <IndexTheCovenant /> },
      {
        path: "FinancialMovement/DailyRestrictions",
        element: <IndexDailyRestrictionsGL />,
      },
      {
        path: "FinancialMovement/FinancialMovementSettings",
        element: <FinancialMovementSettings />,
      },

      // Stores
      { path: "Stores/MovementOfItems", element: <IndexMovementOfItems /> },
      { path: "Stores/AdditionStep", element: <AdditionStep /> },
      { path: "Stores/ExchangeMovement", element: <ExchangeMovement /> },
      {
        path: "Stores/PhysicalInventoryMovement",
        element: <IndexPhysicalInventoryMovement />,
      },
      {
        path: "Stores/StoreTransferMovement",
        element: <IndexStoreTransferMovement />,
      },
      { path: "Stores/StoresSettings", element: <StoresSettings /> },

      // Purchases
      { path: "Purchases/SupplierCodes", element: <IndexSupplierCodes /> },
      { path: "Purchases/BuyOrder", element: <BuyOrder /> },
      { path: "Purchases/PurchaseOrder", element: <PurchaseOrder /> },
      { path: "Purchases/PurchaseInvoice", element: <PurchaseInvoice /> },
      { path: "Purchases/ReturnedPurchases", element: <ReturnedPurchases /> },
      { path: "Purchases/Settings", element: <PurchasingSettings /> },

      // Sales
      { path: "Sales/CustomersCode", element: <IndexCustomerCodes /> },
      { path: "Sales/OfferPrice", element: <OfferPrice /> },
      { path: "Sales/OrderedSupply", element: <OrderedSupply /> },
      { path: "Sales/Sales", element: <Sales /> },
      { path: "Sales/SalesBouncer", element: <SalesBouncer /> },
      { path: "Sales/Settings", element: <SalesSettings /> },

      // Order Follow-Up
      { path: "FollowUpOnOrders/Settings", element: <FollowUpOnOrders /> },
      { path: "FollowUpOnOrders/Customer", element: <IndexCustomer /> },
      {
        path: "FollowUpOnOrders/InvoiceDeliveries",
        element: <IndexInvoiceDeliveries />,
      },

      // Production
      { path: "Production/WorkOrder", element: <IndexWorkOrder /> },
      {
        path: "Production/ReceivingTheWorkOrder",
        element: <IndexReceivingTheWorkOrder />,
      },
      { path: "Production/Examination", element: <IndexExamination /> },
      {
        path: "Production/ReceiveTheFinishedProduct",
        element: <IndexReceiveTheFinishedProduct />,
      },
      { path: "Production/Settings", element: <ProductionSettings /> },

      // Project Management
      { path: "ProjectsManagement/Projects", element: <IndexProjects /> },
      {
        path: "ProjectsManagement/ProjectsManagement",
        element: <IndexProjectsManagement />,
      },
      { path: "ProjectsManagement/Mission", element: <IndexMission /> },
      {
        path: "ProjectsManagement/Settings",
        element: <ProjectsManagementSettings />,
      },

      // Contracting
      { path: "Contracting/Contracting", element: <IndexContracting /> },
      { path: "Contracting/AbstractCodes", element: <AbstractCodes /> },
      { path: "Contracting/OperationTerms", element: <IndexOperationTerms /> },
      {
        path: "Contracting/ContractorExtract",
        element: <IndexContractorExtract />,
      },
      { path: "Contracting/Settings", element: <ContractingSettings /> },

      // Installment Management
      {
        path: "InstallmentManagement/Customer",
        element: <IndexCustomerInstallmentManagement />,
      },
      { path: "InstallmentManagement/AddData", element: <IndexAddData /> },
      {
        path: "InstallmentManagement/Installments",
        element: <IndexInstallments />,
      },

      // CRM
      { path: "CRM/CustomerCRM", element: <IndexCustomerCRM /> },
      { path: "CRM/CustomerFollowUp", element: <IndexCustomerFollowUp /> },
      { path: "CRM/InitialRequest", element: <InitialRequest /> },
      { path: "CRM/SellOrder", element: <SellOrder /> },

      // Maintenance
      {
        path: "Maintenance/Customers",
        element: <IndexCustomerCodesMaintenance />,
      },
      { path: "Maintenance/MaintenanceOrder", element: <MaintenanceOrder /> },
      {
        path: "Maintenance/MaintenanceInvoice",
        element: <MaintenanceInvoice />,
      },
      {
        path: "Maintenance/MaintenanceApostate",
        element: <MaintenanceApostate />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
]);
