import { Checkbox, Form, Select, Button, message } from "antd";
import Heading from "components/Heading/Heading";
import {
  useGetAccountsQuery,
  useGetAccountsSettingQuery,
  useUpdateAccountsSettingMutation,
} from "../../../redux/Service/Accounts";
import { useEffect, useState } from "react";

const { Option } = Select;

// Define an array with field key and corresponding label
const fieldOptions = [
  { key: "AccEdafaId", label: "حساب الإضافة للمخزون" },
  { key: "AccSarfId", label: "حساب الصرف للمخزون" },
  { key: "AccRetSarfId", label: "حساب مرتد الصرف للمخزون" },
  { key: "AccGiftEdafaId", label: "حساب الإضافة للهدايا" },
  { key: "AccGiftSarfId", label: "حساب الصرف للهدايا" },
  { key: "AccTalefId", label: "حساب تالف المخزون" },
  { key: "AccTaxId", label: "حساب الضرائب" },
  { key: "AccExpId", label: "حساب المصروفات" },
  { key: "AccGainDiscId", label: "حساب الخصم المكتسب" },
  { key: "AccAllowDiscId", label: "حساب الخصم المسموح به" },
  { key: "AccNetProffitId", label: "صافى الربح" },
  { key: "AccNetLostId", label: "صافى الخسارة" },
];

function AccountsSettings() {
  const [form] = Form.useForm();

  const {
    data: accountsData,
    isLoading: isAccountsLoading,
    isError: isAccountsError,
  } = useGetAccountsQuery(undefined);
  const accounts = accountsData?.ResponseObject ?? [];

  const {
    data: configData,
    isLoading: isConfigLoading,
    isError: isConfigError,
  } = useGetAccountsSettingQuery(undefined);
  const config = configData?.ResponseObject ?? {};

  const [updateConfig, { isLoading: isUpdating }] =
    useUpdateAccountsSettingMutation();

  useEffect(() => {
    if (Object.keys(config).length) {
      form.setFieldsValue(config);
    }
  }, [config, form]);

  const onFinish = async (values: any) => {
    console.log("Submitted values:", values);
    try {
      const response = await updateConfig(values).unwrap();
      if (response?.ResponseObject === true) {
        message.success("تم تعديل بنجاح");
      } else {
        message.error("حدث خطأ أثناء العملية");
      }
    } catch (error) {
      message.error("فشل الاتصال بالخادم");
    }
  };

  return (
    <>
      <Heading name="الإعدادات الحسابات" />

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={config}
      >
        {/* Checkbox for IsMustUseCost */}
        <div className="flex justify-center gap-10 my-10 flex-wrap font-semibold">
          <Form.Item name="IsMustUseCost" valuePropName="checked" noStyle>
            <Checkbox>الربط بمراكز التكلفة</Checkbox>
          </Form.Item>
        </div>

        {/* Render each select field dynamically */}
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
          {fieldOptions.map((field) => (
            <div
              key={field.key}
              className="grid grid-cols-6 gap-3 items-center"
            >
              <label className="col-span-2 text-start" htmlFor={field.key}>
                {field.label}
              </label>
              <div className="col-span-4">
                <Form.Item name={field.key} noStyle>
                  <Select
                    key={field.key}
                    id={field.key}
                    showSearch
                    placeholder="اختر الحساب"
                    style={{ width: "10rem" }}
                    optionLabelProp="children"
                    filterOption={(input, option: any) =>
                      (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {accounts?.map((account) => (
                      <Option
                        key={String(account.Id)}
                        value={String(account.Id)}
                      >
                        {account.AccName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          ))}
        </div>

        {/* Save button */}
        <div className="text-center mt-5">
          <Button
            type="primary"
            htmlType="submit"
            loading={isConfigLoading || isAccountsLoading || isUpdating}
          >
            حفظ الإعدادات
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AccountsSettings;
